import {IWithdrawalPartnersComponentProps} from "../../../lib/types/components";
import {dynamicCssSize} from "../../../lib/utils/constants/PagesConstants";

const WithdrawalPartnersComponentV2 = (props: IWithdrawalPartnersComponentProps) => {
    const {withdrawalPartnerData, isGetApp} = props;
    return (
        withdrawalPartnerData && Object.keys(withdrawalPartnerData).length ?
            <>
                <style jsx>{`
                  .withdrawalContainer {
                    padding: ${isGetApp?"-1px 0":"18px 0px 4.6vw"}
                    background: #ebebeb;
                    font-family: var(--font-primary);
                  }

                  .headingParent {
                    display: flex;
                    justify-content: center;
                  }

                  .heading {
                    font-size: ${dynamicCssSize(60, 16)};
                    text-transform: uppercase;
                    font-weight: 900;
                    font-style: italic;
                    letter-spacing: 2.25px;
                    max-width: 65vw;
                    text-align: center;
                    color: #35255a;
                  }

                  .imagesRow {
                    padding: ${dynamicCssSize(50, 20)} ${dynamicCssSize(50, 15)};
                  }

                  .imagePartnerContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                  }

                  .imageParent {
                    width: ${dynamicCssSize(202, 56)};
                    height: ${dynamicCssSize(209, 58)};
                    padding: ${dynamicCssSize(20, 10)};
                    background: url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/withdrawalPartner_container.webp");
                    background-repeat: no-repeat;
                    background-size: contain;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: ${dynamicCssSize(25, 10)};
                  }

                  .imageContainer {
                    position: relative;
                    width: 100%;
                    height: 100%;
                  }

                  @media screen and (max-width: 767px) {
                    .heading {
                      letter-spacing: 0.64px;
                      color: #35255a;
                    }
                  }
                `}</style>
                <div className={"container-fluid withdrawalContainer"}>
                    <div className={"container"}>
                        {/* <div className={"headingParent"}>
                            <h2 className={"heading"}>{withdrawalPartnerData?.text1}</h2>
                        </div>
                        <div className={"row imagesRow"}>
                            {withdrawalPartnerData.images.map((item, idx) => {
                                return (
                                    <div className={"col imagePartnerContainer"}>
                                        <div className={"imageParent"}>
                                            <div className={"imageContainer"}>
                                                <CustomImageComponent src={item.srcV2} alt={item.alt} layout={"fill"}
                                                                      objectFit={"contain"}/>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div> */}
                    </div>
                </div>
            </> :
            <div style={{minHeight: "4.6vw"}}></div>
    )
}

export default WithdrawalPartnersComponentV2;